'use client';
import { Bar, BarChart, ResponsiveContainer, XAxis } from 'recharts';

const TopTalentClient = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={220}>
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          stroke="#4f97d2"
          fontSize={12}
          tickLine={true}
          axisLine={true}
        />
        {/* Render each Bar with conditional fill */}
        {data.map((entry) => (
          <Bar
            key={entry.name}
            dataKey="total"
            fill={entry.name === 'Hired' ? '#e9f264' : '#91bef8'} // Yellow for "Hired", transparent for others
            radius={[4, 4, 0, 0]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TopTalentClient;
