"use client"
import { useEffect, useState } from 'react'
import { Motion } from "../../reusable-components/Motion"
import { swapTwoRandomItemsInGrid } from '../Onboard'

export function InfiniteSwapList({ items }: { items: string[][] }) {
    const [shuffledItems, setShuffledItems] = useState(items)
  
    useEffect(() => {
      const interval = setInterval(() => {
        setShuffledItems((prev) => swapTwoRandomItemsInGrid(prev)) // Swap two items every 2 seconds
      }, 1000)
  
      return () => clearInterval(interval) 
    }, [])
  
    return (
      <Motion
       type="div"
        className="grid grid-cols-6"
        layout
      >
        {shuffledItems.flat().map((item, index) => (
          <Motion
           type="div"
            key={item + index} // Unique key to prevent layout jumping
             // Enable smooth layout animations for each item
             layoutId={`emoji-123-${index}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, ease: "easeInOut" }} // Smooth transition for the layout change
            className="w-[8rem] px-1 mx-auto  h-full text-[38px] flex items-center justify-between  rounded-lg font-semibold uppercase tracking-widest"
          >
            {item}
          </Motion>
        ))}
      </Motion>
    )
  }