"use client";
import Cal from "@calcom/embed-react";
import { ChevronRightIcon } from "lucide-react";
import { useTheme } from 'next-themes';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalTrigger
} from "../ui/animated-modal";

export function RequestDemo() {

  const { theme } = useTheme();
  const isDarkTheme = theme == 'dark';
  const calURL = 'everything-talent-ai/support';
  return (

    <div className="flex items-center justify-center">
      <Modal >
        <ModalTrigger className="flex justify-center border py-[10px] group/modal-btn">
          <span className="flex group-hover/modal-btn:translate-x-40 ml-2 text-center transition duration-500">
            Request a Demo
            <ChevronRightIcon className=" translate-x-1 transition" />
          </span>
          <div className="-translate-x-40 group-hover/modal-btn:translate-x-0 flex items-center justify-center absolute inset-0 transition duration-500 text-white z-20">
            📲
          </div>
        </ModalTrigger>
        <ModalBody >
          <ModalContent className="w-full max-w-[750px] ">
            <h4 className="text-lg md:text-2xl text-neutral-600 dark:text-neutral-100 font-bold text-center mb-8" >
              Book your Demo to{" "}
              <span className="px-1 py-0.5 rounded-md bg-gray-100 dark:bg-neutral-800 dark:border-neutral-700 border border-gray-200">
                Start Hiring
              </span>{" "}
              now! 📲
            </h4>

            <div className="rounded-2xl">
              <Cal
                calLink={calURL}
                style={{
                  width: '100%',
                  height: '100%',
                  overflow:"hidden",
                  borderRadius:"8px",
                }}
                config={{ layout: 'month_view', theme: isDarkTheme ? 'dark' : 'light',borderRadius:"8px", }}
              />
            </div>

          </ModalContent>
        </ModalBody>
      </Modal>
    </div>
  );
}