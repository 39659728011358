import { InfiniteSwapList } from "./client-component/OnboardShuffle"


// Swap function to shuffle two random items in a 2D array
export const swapTwoRandomItemsInGrid = (array: string[][]) => {
  const newArray = array.map(row => [...row])
  const row1 = Math.floor(Math.random() * newArray.length)
  const col1 = Math.floor(Math.random() * newArray[row1].length)
  let row2 = Math.floor(Math.random() * newArray.length)
  let col2 = Math.floor(Math.random() * newArray[row2].length)

  // Ensure the two indices are not the same
  while (row1 === row2 && col1 === col2) {
    row2 = Math.floor(Math.random() * newArray.length)
    col2 = Math.floor(Math.random() * newArray[row2].length)
  }

  // Swap the elements
  [newArray[row1][col1], newArray[row2][col2]] = [newArray[row2][col2], newArray[row1][col1]]
  return newArray
}

export default function Onboard() {
  const emojis = [
    ['📚', '🗓️', '🎒', '🖥️', '🪑', '📖'],
    ['🧑‍🦱', '🧭', '🏆', '📋', '✅', '🌟'],
    ['🔍', '✉️', '📈', '⚙️', '🔒', '💡']
  ];


  return (
    <>
      <InfiniteSwapList items={emojis} />
    </>
  )
}
