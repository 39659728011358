"use client"
import { Bar, BarChart, ResponsiveContainer, XAxis } from 'recharts';

const ChartjsClient = ({data}) => {
  return (
    <ResponsiveContainer width="100%" className="" height={200}>
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          stroke="#4f97d2"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        <Bar dataKey="total" fill="#4379F2" radius={[4, 4, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartjsClient;
