'use client';

import { useEffect, useRef, useState } from 'react';
import { T } from '@/components/ui/Typography';
import { classNames } from '@/utils/classNames';
import { LayoutGrid } from "@/components/ui/layout-grid";



const cards = [
  {
    id: 1,
     heading: 'Comprehensive Applicant Tracking & Management', 
     discription:"Our free ATS lets you manage all aspects of your recruiting process with ease. Post job vacancies, monitor submissions and distribute tests in one easy-to-use system.",
    className: "md:col-span-2",
     thumbnail:
       "https://images.unsplash.com/photo-1476231682828-37e571bc172f?q=80&w=3474&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
     src1:"/mockups/ATS1.png",
     src2:"/mockups/ATS1-L.png",
     icon:<svg
     className="w-3 h-3 fill-current"
     viewBox="0 0 12 12"
     xmlns="http://www.w3.org/2000/svg"
   >
     <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
   </svg>
  },
  {
    id: 2,
     heading: 'Cutting-Edge Tech Assessment Tools', 
     discription:"Hire the best employees with unmatched accuracy by choosing our artificial intelligence tests. This helps ensure that all essential aspects of the candidate’s capabilities are tested as well as provide fairness by incorporating strong anti-cheat measures.",
    className: "col-span-1",
    thumbnail:
       "https://images.unsplash.com/photo-1464457312035-3d7d0e0c058e?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    src1: "/assets/home/bg1.png",
    src2: "/assets/home/bg1L.png",
    icon: <svg
    className="w-3 h-3 fill-current"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z"
      fillRule="nonzero"
    />
  </svg>
  },
  {
    id: 3,
     heading: 'In-Depth Candidate Reporting', 
     discription:"Keep track of candidates’ results with powerful, in-depth reports. Use data analysis to improve your hiring strategy as you make decisions in your hiring process.",
    className: "col-span-1",
     thumbnail:
       "https://images.unsplash.com/photo-1588880331179-bc9b93a8cb5e?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    src1:"/mockups/Report1.png",
    src2:"/mockups/Report1-L.png",
    icon: <svg
    className="w-3 h-3 fill-current"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z"
      // fill="#191919"
      fillRule="nonzero"
    />
  </svg>
  }
];


const MainFeatures = () => {
  const [tab, setTab] = useState<number>(1);
  const [selectedCard, setSelectedCard] = useState(cards[0] || null);
  const tabs = useRef<HTMLDivElement>(null);

  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement)
      tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`;
  };

  useEffect(() => {
    heightFix();
  }, []);

  return (
    <section className="relative w-full ">
      <div className="relative md:container px-4 sm:px-6">
        <div className='flex flex-col gap-[30px]'>
          {/* Section header */}
          <div className="max-w-3xl ">
            <div className='flex items-center justify-center lg:justify-start'>
              <T.Small className="text-gray-600 dark:text-slate-400 bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent font-bold">
                AI-Powered Tools
              </T.Small>
            </div>
            <h2 className="mt-5 mb-5 text-4xl leading-[44px]  font-semibold text-gray-900 dark:text-white">
            Elevate Your Talent Acquisition
            </h2>
            <p className="text-gray-500 sm:text-xl sm:leading-[30px] dark:text-slate-400">
            Take your hiring journey to the next level with our AI system that aims to improve each stage of the recruitment process. Our platform enhances your efforts making certain that you assess and recruit the best candidates efficiently and quickly. Find out how our solutions can revolutionise the way you recruit.
            </p>
          </div>

         
          <div className="md:grid md:grid-cols-12 "> 
            <div
              className="max-w-xl md:max-w-none md:w-full mx-auto my-auto md:col-span-7 lg:col-span-6 md:mt-6"
              data-aos="fade-right"
            >          
              <div className="mb-8 md:mb-0">
                {cards?.map((card)=>
                <a
                className="flex items-center text-lg rounded transition group duration-300 ease-in-out"
                href="#0"
                key={card?.id}
                onClick={(e) => {
                  // e.preventDefault();
                  setTab(card?.id);
                  setSelectedCard(card);
                }}
              >
                <div
                  className={classNames(
                    'px-6 py-6 max-w-4xl md:max-w-xl rounded-xl transition duration-300 ease-in-out',
                    tab !== card?.id
                      ? 'group-hover:cursor-pointer'
                      : 'bg-gray-100 dark:bg-slate-800',
                  )}
                >
                  <div className="flex items-center justify-center border border-gray-300 dark:border-slate-700 bg-muted dark:bg-slate-800 w-10 h-10 rounded-lg mb-4 bg-primary-100 dark:bg-primary-900">
                    {card?.icon}
                  </div>
                  <T.P className="font-semibold text-xl leading-[30px] mb-2">
                   {card?.heading}
                  </T.P>
                  <T.P className="text-gray-600 dark:text-slate-400 text-base">
                    {card?.discription}
                  </T.P>
                
                </div>
            
              </a>
                )}
              </div>
            </div>
            <div className='lg:col-span-6 col-span-5'>
             <LayoutGrid cards={cards} selectedCard={selectedCard} setSelectedCard={setSelectedCard} setTab={setTab}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainFeatures;
