"use client"

import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import {swapTwoRandomItems} from "../RandomQuestion";
export function InfiniteSwapList({ items }: { items: string[], }) {
    const [shuffledItems, setShuffledItems] = useState(items)
  
    useEffect(() => {
      const interval = setInterval(() => {
        setShuffledItems((prev) => swapTwoRandomItems(prev)) // Swap two items every 1 second
      }, 1500)
  
      return () => clearInterval(interval) // Clean up the interval on unmount
    }, [])
  
    return (
      <div className="flex flex-col items-center justify-center gap-2 w-full">
        <AnimatePresence>
          {shuffledItems.map((item, index) => (
            <motion.div key={item} className='w-full z-10'     // key={item}
              initial={{ opacity: 0, scale: 0.8 }} 
              animate={{ opacity: 1, scale: 1 }} 
              exit={{ opacity: 0, scale: 0.8 }} 
              transition={{ duration: 0.5 }} 
              layout
              dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }} // Constraint the drag area to avoid full movement
              whileDrag={{ scale: 1.1 }} 
              >
              
            <div
                  className={`w-full  hidden dark:flex h-full py-3  justify-center  text-[14px] font-semibold uppercase tracking-widest p-2 border border-blue-300 dark:border-blue-700 shadow flex-col items-center px-4 rounded-lg -right-[30px] top-4 bg-white dark:bg-gray-800
                  `}
            >
              {item}
            </div>
   
            <div
                  className={`w-full shadow-lg dark:hidden h-full py-3 flex items-center justify-center rounded-lg text-[14px] font-semibold uppercase tracking-widest 
                    ${index === 0 && 'bg-blue-500 text-white dark:bg-blue-400 dark:text-white'}  // Blue
                    ${index === 1 && 'bg-green-500 text-white dark:bg-green-400 dark:text-white'}  // Green
                    ${index === 2 && 'bg-yellow-500 text-white  dark:bg-yellow-400 dark:text-white'}  // Yellow
                    ${index === 3 && 'bg-[#E8D44D]  text-white dark:bg-[#C5B730] dark:text-white'}  // Gold 
                  `}
            >
              {item}
            </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    )
  }