import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
 
type Card = {
  src2: string | undefined;
  src1: string | undefined;
  id: number;
  heading: JSX.Element | React.ReactNode | string;
  discription:string;
  className: string;
  thumbnail: string;
};

 
export const LayoutGrid = ({ cards,selectedCard ,setSelectedCard, setTab}: any) => {
  const [selected, setSelected] = useState(selectedCard);
  const [lastSelected, setLastSelected] = useState<Card | null>(null);
 
 
  useEffect(()=>{
      setSelected(selectedCard);
  },[selectedCard])
  const handleClick = (card: Card) => {
    setLastSelected(selected);
    setSelected(card);
  };
 
  const handleOutsideClick = () => {
    setLastSelected(selected);
    setSelected(null);
    setSelectedCard(null)
  };

  const handleImgClick = (card: Card)=>{
    setSelectedCard(card);
    setTab(card.id)
  }
 
  return (
    <div className="w-full h-full  grid gap-4 relative">
      {cards.map((card, i) => (
        <div key={i} className={cn(card.className, "")}>
          <motion.div
            onClick={() => {handleClick(card); handleImgClick(card)}}
            className={cn(
              card.className,
              "relative overflow-hidden"
              ,
              selected?.id === card.id
                ? "rounded-lg cursor-pointer absolute shadow-md hover:shadow-lg transition-all duration-200 hover:scale-105 dark:border-[#2c2c2c] border-[1px]  top-0 inset-0 h-[52%] w-full z-20  flex-wrap flex-col":selected?.id !== 1?`z-10 ${card.id === 2?"right-0":""} bottom-0 absolute dark:border-[#2c2c2c] border  h-[45%] w-[48%] rounded-xl`:"z-10 bottom-0 absolute  dark:border-[#2c2c2c] border h-[45%] w-[48%] rounded-xl"

            )}
            layoutId={`card-${card.id}`}
          >
            {selected?.id === card.id && <SelectedCard selected={selected} />}
            {selected?.id === card.id ? <ImageComponent card={card} objectDetails={"object-fit"}/>: <ImageComponent card={card} objectDetails={"object-cover"}/>}
          </motion.div>
        </div>
      ))}
      <motion.div
        onClick={handleOutsideClick}
        className={cn(
          "absolute h-full w-full left-0 top-0 opacity-0 z-0",
          selected?.id ? "pointer-events-auto" : "pointer-events-none"
        )}
        animate={{ opacity: selected?.id ? 0.3 : 0 }}
      />
    </div>
  );
};
 
const ImageComponent = ({objectDetails, card }: { card: Card,objectDetails:string }) => {
  return (
    <>

    <motion.img
      layoutId={`imageL-${card.id}-image`}
      src={card.src2}
      height="400"
      width="400"
      className={cn(
        "object-fit rounded-lg dark:hidden border object-left absolute inset-0 h-full w-full transition duration-200 ",
        objectDetails
      )}
      alt="src"
    />
    <motion.img
      layoutId={`imageN-${card.id}-image`}
      src={card.src1}
      height="400"
      width="400"
      className={cn(
        "object-fit rounded-lg object-left hidden dark:block dark:border-[#2c2c2c] border absolute inset-0 h-full w-full transition duration-200 ",
        objectDetails
      )}
      alt="src"
    />
    </>
  );
};
 
const SelectedCard = ({ selected }: { selected: Card | null }) => {
   
  return (
    <div className="bg-transparent  h-full w-full border dark:border-[#2c2c2c] flex flex-col justify-end rounded-lg shadow-lg relative z-30 text-[#ffffff]">
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 0.6,
        }}
        className="absolute inset-0 h-full w-full opacity-60 z-0"
      />
      <motion.div
        layoutId={`heading-${selected?.id}`}
        initial={{
          opacity: 0,
          y: 100,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
          y: 100,
        }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        className="relative px-8 pb-4 z-30 flex flex-col" 
      >
       {/* <span>{selected?.heading}</span> <br/>
        <span className="text-xs"> {selected?.discription}</span> */}
      </motion.div>
    </div>
  );
};