import { InfiniteSwapList } from './client-component/InfiniteSwapList'

// Swap function to shuffle two random items
export const swapTwoRandomItems = (array: any[]) => {
  const newArray = [...array]
  const index1 = Math.floor(Math.random() * newArray.length)
  let index2 = Math.floor(Math.random() * newArray.length)

  // Ensure the two indices are not the same
  while (index1 === index2) {
    index2 = Math.floor(Math.random() * newArray.length)
  }

  // Swap the elements visually
  [newArray[index1], newArray[index2]] = [newArray[index2], newArray[index1]]
  return newArray
}

export default function RandomQuestion() {
  return (
    <>
      <InfiniteSwapList items={['Randomize Questions', 'Ensure Fairness', 'Testing Consistency', 'Unique Experience']} />
    </>
  )
}
